/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */

import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Item } from '../../../app/core/shared/item.model';
import { StyledItemSectionComponent } from '../../../app-atmire/item-page/field-components/section/styled-item-section.component';

@Component({
  selector: 'ds-client-item-info-sections',
  templateUrl: './item-info-sections.component.html',
  styleUrls: ['./item-info-sections.component.scss']
})
export class ItemInfoSectionsComponent {
  @Input() dso: Item;

  @Input() sectionClass: string;

  @Input() full = false;

  @ViewChildren(StyledItemSectionComponent) sections: QueryList<StyledItemSectionComponent>;

  public searchDimension(value: string): string {
    return `/search?f.sustainDimension=${value},equals`;
  }

  public searchTopic(value: string): string {
    return `/search?f.topics=${value},equals`;
  }

  public searchIssue(value: string): string {
    return `/search?f.issues=${value},equals`;
  }

  public languageLabelTransform(value: string): string {
    return `language.${value}.label`;
  }

}
