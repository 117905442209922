/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
/**
 * This component renders a simple item page.
 * The route parameter 'id' is used to request the item it represents.
 * All fields of the item that should be displayed, are defined in its template.
 */
import { Component, ElementRef, ViewChild } from '@angular/core';
import { rendersSuggestionResult } from '../../suggestion.decorator';
import { FilterSuggestion } from '../../../../../../core/search/models/filter-suggestion.model';
import { SuggestionResultComponent } from '../suggestion-result.component';
import { Params, Router } from '@angular/router';
import { isNotEmpty } from '../../../../../../../app/shared/empty.util';

/**
 * This component renders a suggestion result for a filter suggestion
 */
@Component({
  selector: 'ds-filter-suggestion-result',
  styleUrls: ['./filter-suggestion-result.component.scss', '../suggestion-result.component.scss'],
  templateUrl: './filter-suggestion-result.component.html'
})
@rendersSuggestionResult(FilterSuggestion)
export class FilterSuggestionResultComponent extends SuggestionResultComponent<FilterSuggestion> {

  /**
   * The filter query parameters to apply when this filter suggestion is clicked
   */
  filterParam: Params;

  /**
   * The element ref to the link
   */
  @ViewChild('link', {static: false}) link: ElementRef;

  constructor(private router: Router) {
    super();
  }

  ngOnInit() {
    this.displayValue = isNotEmpty(this.suggestion.hitHighlights) && isNotEmpty(this.suggestion.hitHighlights[this.category.name]) ? this.suggestion.hitHighlights[this.category.name][0].toString() : this.suggestion.label;
  }

  /**
   * Method to navigate to the right page when clicking the suggestion result when it's already active
   */
  select() {
    if (this.active.getValue() && isNotEmpty(this.suggestion.queryParams)) {
      const url = this.router.url;
      const parts = url.split('/');
      const uuid = parts.pop();
      if (uuid !== 'home') {
        this.router.navigateByUrl(url + '?' + this.suggestion.queryParams[0] + '&scope=' + uuid);
      } else {
        this.router.navigateByUrl('/search?' + this.suggestion.queryParams[0] );
      }
    }
    return false;
  }

  /**
   * Method to set the focus on the link in the view
   */
  focus() {
    this.link.nativeElement.focus();
  }
}
