<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->

<!--Info Section-->
<ds-styled-item-section [sectionClass]="sectionClass">
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['dcterms.accessRights']"
                               [label]="'item-search-result-list-element.access-rights' | translate">
    </ds-styled-item-page-field>
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['fsc.certificationbody']"
                               [label]="'item-search-result-list-element.certification-body' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['fscdoc.version.number', 'is.extent.version']"
                               [label]="'item-search-result-list-element.version-number' | translate">
    </ds-styled-item-page-field>
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['fsc.nameofthecompany']"
                               [label]="'item-search-result-list-element.company-name' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['dc.description.sponsorship', 'is.contributor.funder']"
                               [label]="'item-search-result-list-element.sponsers' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['todo.todo.todo']"
                               [label]="'item-search-result-list-element.license-code' | translate">
    </ds-styled-item-page-field>
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['todo.todo.todo']"
                               [label]="'item-search-result-list-element.relevance' | translate">
    </ds-styled-item-page-field>
  </div>
</ds-styled-item-section>

<!--Dates Section-->
<ds-styled-item-section [sectionClass]="sectionClass">
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['dc.date.submitted']"
                               [label]="'item-search-result-list-element.submission-date' | translate">
    </ds-styled-item-page-field>
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['fsc.transitionEndDate']"
                               [label]="'item-search-result-list-element.transition-date' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['fscdoc.review.yeargrgr', 'is.dateReviewedgrgr']"
                               [label]="'item-search-result-list-element.review-year' | translate">
    </ds-styled-item-page-field>
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['dc.date.valid', 'dcterms.valid']"
                               [label]="'item-search-result-list-element.effective-date' | translate">
    </ds-styled-item-page-field>
  </div>
</ds-styled-item-section>

<!--Topics Section-->
<ds-styled-item-section [sectionClass]="sectionClass">
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [valueClass]="'content-pill secondary-pill mb-2'"
                               [separator]="undefined"
                               [initialSize]="3"
                               [displayLink]="true"
                               [externalLink]="true"
                               [linkTransform]="searchDimension"
                               [fields]="['fsc.focus.sustainDimension']"
                               [label]="'item-search-result-list-element.sustain-dimension' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [valueClass]="'content-pill secondary-pill mb-2'"
                               [separator]="undefined"
                               [initialSize]="3"
                               [displayLink]="true"
                               [externalLink]="true"
                               [linkTransform]="searchTopic"
                               [fields]="['fsc.topic.economic', 'fsc.topic.environmental', 'fsc.topic.political', 'fsc.topic.social']"
                               [label]="'item-search-result-list-element.topic' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [valueClass]="'content-pill secondary-pill mb-2'"
                               [separator]="undefined"
                               [initialSize]="3"
                               [displayLink]="true"
                               [externalLink]="true"
                               [linkTransform]="searchIssue"
                               [fields]="['fsc.issue.economic', 'fsc.issue.environmental', 'fsc.issue.social']"
                               [label]="'item-search-result-list-element.issue' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [valueClass]="'content-pill secondary-border-pill mb-2'"
                               [separator]="undefined"
                               [initialSize]="3"
                               [fields]="['dc.subject', 'fsc.subject']"
                               [label]="'item-search-result-list-element.subject-keywords' | translate">
    </ds-styled-item-page-field>
  </div>
</ds-styled-item-section>

<!--Forest Section-->
<ds-styled-item-section [sectionClass]="sectionClass">
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['is.coverage.region']"
                               [label]="'item-search-result-list-element.region' | translate">
    </ds-styled-item-page-field>
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['is.coverage.country']"
                               [label]="'item-search-result-list-element.country' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['fsc.focus.forestZone']"
                               [label]="'item-search-result-list-element.zone' | translate">
    </ds-styled-item-page-field>
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['fsc.focus.forestType']"
                               [label]="'item-search-result-list-element.forest-type' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['fsc.focus.tenureOwnership']"
                               [label]="'item-search-result-list-element.tenure-ownership' | translate">
    </ds-styled-item-page-field>
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['fsc.focus.tenureManagement']"
                               [label]="'item-search-result-list-element.tenure-management' | translate">
    </ds-styled-item-page-field>
  </div>
</ds-styled-item-section>

<!--Pesticides Section-->
<ds-styled-item-section [sectionClass]="sectionClass">
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['is.pesticide.alternativeStrategy']"
                               [label]="'item-search-result-list-element.alternative-strategy' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['is.pesticide.alternativeMethod']"
                               [label]="'item-search-result-list-element.alternative-method' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['is.pesticide.activeingredient']"
                               [label]="'item-search-result-list-element.active-ingredient' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['is.pesticide.alternativeTrial']"
                               [label]="'item-search-result-list-element.alternative-trial' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['is.pesticide.alternativeType']"
                               [label]="'item-search-result-list-element.alternative-type' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [initialSize]="3"
                               [fields]="['is.pesticide.pestType']"
                               [label]="'item-search-result-list-element.pest-type' | translate">
    </ds-styled-item-page-field>
  </div>
</ds-styled-item-section>

<!--Evidence Section-->
<ds-styled-item-section [sectionClass]="sectionClass">
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [fields]="['fsc.evidenceCategory']"
                               [label]="'item-search-result-list-element.evidence-category' | translate"
                               [labelTooltip]="'item-search-result-list-element.evidence-category.info' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [fields]="['is.evidenceType']"
                               [label]="'item-search-result-list-element.evidence-type' | translate"
                               [labelTooltip]="'item-search-result-list-element.evidence-type.info' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field class="w-100"
                               [item]="dso"
                               [fields]="['is.evidenceSubType']"
                               [label]="'item-search-result-list-element.evidence-subtype' | translate"
                               [labelTooltip]="'item-search-result-list-element.evidence-subtype' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field [item]="dso"
                               [fields]="['is.evaluation.collection']"
                               [label]="'item-search-result-list-element.collection-methods' | translate"
                               [labelTooltip]="'item-search-result-list-element.collection-methods.info' | translate">
    </ds-styled-item-page-field>
  </div>
</ds-styled-item-section>

<!--Evaluation Section-->
<ds-styled-item-section [sectionClass]="sectionClass" *ngIf="full">
  <div class="d-flex">
    <ds-styled-item-page-field [item]="dso"
                               [fields]="['is.evaluation.findings']"
                               [ul]="true"
                               [label]="'item-search-result-list-element.evaluation-findings' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field [item]="dso"
                               [fields]="['is.evaluation.recommendations']"
                               [ul]="true"
                               [label]="'item-search-result-list-element.evaluation-recommendations' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field [item]="dso"
                               [fields]="['is.evaluation.quotes']"
                               [ul]="true"
                               [label]="'item-search-result-list-element.evaluation-quotes' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field [item]="dso"
                               [fields]="['is.evaluation.scope']"
                               [ul]="true"
                               [label]="'item-search-result-list-element.evaluation-scope' | translate">
    </ds-styled-item-page-field>
  </div>
  <div class="d-flex">
    <ds-styled-item-page-field [item]="dso"
                               [fields]="['is.evaluation.notes']"
                               [ul]="true"
                               [label]="'item-search-result-list-element.evaluation-notes' | translate">
    </ds-styled-item-page-field>
  </div>
</ds-styled-item-section>
