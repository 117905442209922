/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../app/shared/shared.module';
import { SearchModule } from '../../app/shared/search/search.module';
import { FormsModule } from '@angular/forms';
import { RootModule } from '../../app/root.module';
import { NavbarModule } from '../../app/navbar/navbar.module';
import { ItemPageModule } from '../../app/item-page/item-page.module';
import { CollectionAdminSearchResultListElementComponent } from './app/admin/admin-search-page/admin-search-results/admin-search-result-list-element/collection-search-result/collection-admin-search-result-list-element.component';
import { CollectionSearchResultListElementComponent } from './app/shared/object-list/search-result-list-element/collection-search-result/collection-search-result-list-element.component';
import { CommunityAdminSearchResultListElementComponent } from './app/admin/admin-search-page/admin-search-results/admin-search-result-list-element/community-search-result/community-admin-search-result-list-element.component';
import { CommunitySearchResultListElementComponent } from './app/shared/object-list/search-result-list-element/community-search-result/community-search-result-list-element.component';
import { UntypedItemComponent } from './app/item-page/simple/item-types/untyped-item/untyped-item.component';
import { ItemListElementComponent } from './app/shared/object-list/item-list-element/item-types/item/item-list-element.component';
import { ItemSearchResultListElementComponent } from './app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';
import { FooterComponent } from './app/footer/footer.component';
import { HeaderComponent } from './app/header/header.component';
import { HeaderNavbarWrapperComponent } from './app/header-nav-wrapper/header-navbar-wrapper.component';
import { HomeNewsComponent } from './app/home-page/home-news/home-news.component';
import { HomePageComponent } from './app/home-page/home-page.component';
import { AuthNavMenuComponent } from './app/shared/auth-nav-menu/auth-nav-menu.component';
import { CommunityListComponent } from './app/community-list-page/community-list/community-list.component';
import { StatisticsModule } from '../../app/statistics/statistics.module';
import { HomePageModule } from '../../app/home-page/home-page.module';
import { CommunityListPageModule } from '../../app/community-list-page/community-list-page.module';
import { AtmireItemPageModule } from '../../app-atmire/item-page/atmire-item-page.module';
import { ClientItemPageModule } from '../../app-client/item-page/client-item-page.module';
import { PublicationComponent } from './app/item-page/simple/item-types/publication/publication.component';
import { AtmireAppModule } from '../../app-atmire/atmire-app.module';
import { RelatedItemListElementComponent } from './app/shared/object-list/item-list-element/item-types/related-item/related-item-list-element.component';
import { RelatedItemSearchResultListElementComponent } from './app/shared/object-list/search-result-list-element/item-search-result/item-types/related-item/related-item-search-result-list-element.component';
import { ThumbnailComponent } from './app/thumbnail/thumbnail.component';
import { StartsWithTextComponent } from './app/shared/starts-with/text/starts-with-text.component';
import { StartsWithDateComponent } from './app/shared/starts-with/date/starts-with-date.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { ClientSharedModule } from '../../app-client/shared/client-shared.module';
import { ClientAppModule } from '../../app-client/client-app.module';
import { ResultsBackButtonModule } from '../../app/shared/results-back-button/results-back-button.module';
import { ComcolModule } from '../../app/shared/comcol/comcol.module';
import {
  ItemPageDiscoveryLinksComponent
} from './app/item-page/simple/field-components/discovery-links/item-page-discovery-links.component';

/**
 * Add components that use a custom decorator to ENTRY_COMPONENTS as well as DECLARATIONS.
 * This will ensure that decorator gets picked up when the app loads
 */
const ENTRY_COMPONENTS = [
  CollectionAdminSearchResultListElementComponent,
  CollectionSearchResultListElementComponent,
  CommunityAdminSearchResultListElementComponent,
  CommunitySearchResultListElementComponent,
  UntypedItemComponent,
  RelatedItemListElementComponent,
  ItemListElementComponent,
  RelatedItemSearchResultListElementComponent,
  ItemSearchResultListElementComponent,
  PublicationComponent,
  StartsWithDateComponent,
  StartsWithTextComponent,
];

const DECLARATIONS = [
  ...ENTRY_COMPONENTS,

  // eager components
  HeaderComponent,
  HeaderNavbarWrapperComponent,
  AuthNavMenuComponent,
  HomeNewsComponent,
  HomePageComponent,
  CommunityListComponent,
  FooterComponent,
  ThumbnailComponent,
  ItemPageDiscoveryLinksComponent,
];

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        SearchModule,
        FormsModule,
        RootModule,
        NavbarModule,
        ItemPageModule,
        AtmireItemPageModule,
        ClientItemPageModule,
        HomePageModule,
        StatisticsModule,
        CommunityListPageModule,
        AtmireAppModule,
        NgbCollapseModule,
        ClientSharedModule,
        ClientAppModule,
        ResultsBackButtonModule,
        ComcolModule,
    ],
  declarations: DECLARATIONS,
  providers: [
    ...ENTRY_COMPONENTS.map((component) => ({ provide: component }))
  ],
})
/**
 * This module is included in the main bundle that gets downloaded at first page load. So it should
 * contain only the themed components that have to be available immediately for the first page load,
 * and the minimal set of imports required to make them work. Anything you can cut from it will make
 * the initial page load faster, but may cause the page to flicker as components that were already
 * rendered server side need to be lazy-loaded again client side
 *
 * Themed EntryComponents should also be added here
 */
export class EagerThemeModule {
}
